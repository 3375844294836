import bigcommerceSparkDefaults from '../bigcommerce-elevate/config.js';

bigcommerceSparkDefaults.Widgets.find((w) => w.name === 'HomeVehicleWidget').location = {
  insertAfter: 'body.default header ~ .body .heroCarousel',
  class: 'cm_vehicle-widget__home_container',
};

export default {
  ...bigcommerceSparkDefaults,
  includes: ['bigcommerce-elevate'],
};
