const baseFitmentFields = globalThis.Convermax.config?.baseFitmentFields || ['Year', 'Make', 'Model'];
const extraFitmentFields = globalThis.Convermax.config?.extraFitmentFields || ['Submodel'];
const wheelsFields = ['wheel_diameter', 'wheel_width', 'wheel_bolt_pattern'];
const tiresFields = ['tire_width', 'tire_aspect', 'tire_rim'];
const ignoreFields = [...wheelsFields, ...tiresFields];

const categorySelectionPageUrl = '/category-list/';

//Check that the store has been integrated with the BigCommerceApp
const getBrandsPageUrl = () => {
  const isAppInstalledStore =
    window.document.querySelector('#cm-brand-list') && window.location.pathname == '/brand-list/';
  return isAppInstalledStore ? '/brand-list/' : '/brands/';
};

const getFitmentSearchTitle = () =>
  window.location.pathname === categorySelectionPageUrl
    ? 'Categories'
    : window.location.pathname === getBrandsPageUrl()
      ? 'Brands'
      : 'Parts';

export default {
  platform: 'bigcommerce',
  SearchRequestDefaults: {
    pageSize: 36,
    sort: window.Convermax.config?.defaultSort ?? 'relevance',
  },
  fitmentSearch: {
    baseFields: baseFitmentFields,
    extraFields: extraFitmentFields,
    categorySelectionPages: [
      { pathname: categorySelectionPageUrl, field: 'category' },
      { pathname: getBrandsPageUrl(), field: 'brand_name' },
    ],
    getFitmentSearchTitle,
    isVehicleSelectionIsolated: !!window.Convermax.config?.isVehicleSelectionIsolated,
  },
  facets: {
    rangedFacet: [{ fields: ['wheel_bore'], step: '1/10//1', slider: { mode: 'LowerBound' } }],
  },
  Widgets: [
    {
      name: 'SearchPage',
      location: '#cm_results',
      template: 'SearchPage',
    },
    {
      name: 'CategoryPage',
      type: 'SearchPage',
      location: ['category', 'brand'].map((className) => `body.${className} .page`).join(),
      template: 'SearchPage',
    },
    {
      name: 'FacetPanel',
      ignoreFields,
    },
    { name: 'SearchResult' },
    {
      name: 'SearchHeader',
      sortEntries: {
        'relevance': 'Best Match',
        'name': 'A to Z',
        'name:desc': 'Z to A',
        'price': 'Price: Ascending',
        'price:desc': 'Price: Descending',
      },
    },
    {
      name: 'SearchBox',
      location: {
        replace: 'header .header-left .navUser-item--search',
        class: 'dropdown dropdown--quickSearch is-open f-open-dropdown cm_search-box-root-container',
      },
      template: 'SearchBox',
    },
    {
      name: 'SearchBoxDialogButton',
      location: {
        insertAfter: 'body .mobileMenu-toggle',
        class: 'cm_search-box-root__mobile',
      },
      template: 'searchBox/dialogButtonMobile',
    },
    {
      name: 'FacetCategoryTiles',
      type: 'FacetTiles',
      location: '#cm_categories',
      template: 'facetTiles/hierarchicalContainer',
    },
    {
      name: 'FacetBrandTiles',
      type: 'FacetTiles',
      location: window.location.pathname == '/brand-list/' ? '#cm-brand-list' : '#cm_brands',
      template: 'facetTiles/hierarchicalContainer',
    },
    {
      name: 'HomeVehicleWidget',
      type: 'VehicleWidget',
      location: { firstChildOf: 'body.default header ~ .body', class: 'cm_vehicle-widget__home_container' },
      template: 'fitmentSearch/homeVehicleWidget',
      columnBreakpoint: 760,
    },
    {
      name: 'SearchVehicleWidget',
      type: 'VehicleWidget',
    },
    {
      name: 'DialogVehicleWidget',
      type: 'VehicleWidget',
    },
    {
      name: 'Garage',
      location: {
        insertAfter: 'header .header-right .navUser-section .navUser-item--wishlist',
        wrapper: 'li',
        class: 'navUser-item navUser-item--cart show',
      },
      template: 'fitmentSearch/garage',
    },
    {
      name: 'VerifyFitment',
      type: 'VehicleWidget',
      location: {
        insertBefore: 'body.product section.productView-details .productView-options',
      },
      template: 'fitmentSearch/verifyFitment',
    },
    {
      name: 'FitmentTableTab',
      type: 'FitmentTable',
      location: { firstChildOf: '.product .tabs[data-tab] .tab.fitment .tab-title' },
      template: 'fitmentSearch/fitmentTableTab',
    },
    {
      name: 'FitmentTable',
      location: {
        lastChildOf: '.product .tabs-contents',
        class: 'tab-content fitment',
        id: 'tab-fitment',
      },
      template: 'fitmentSearch/fitmentTable',
      visibleIf: !document.querySelector('a[href="#tab-fitment"]'),
    },
    {
      name: 'FacetBarWheels',
      type: 'FacetBar',
      fields: wheelsFields,
    },
    {
      name: 'FacetBarTires',
      type: 'FacetBar',
      fields: tiresFields,
    },
    {
      name: 'FacetDialog',
      template: 'FacetDialog',
      ignoreFields,
    },
    {
      name: 'SearchBoxDialog',
      template: 'SearchBoxDialog',
    },
    {
      name: 'vehicle-dialog',
      type: 'ContextDialog',
      template: 'fitmentSearch/dialog',
    },
  ],
};
