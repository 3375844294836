//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-720:_3620,_5064,_3647,_4084,_1284,_1784,_8728,_1848;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-720')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-720', "_3620,_5064,_3647,_4084,_1284,_1784,_8728,_1848");
        }
      }catch (ex) {
        console.error(ex);
      }